<template>
  <div class="container">
    <!-- Menu button -->
    <button @click="toggleMenu" class="menu-button" aria-label="Toggle menu">
      ≡
    </button>

    <!-- Slide-in menu -->
    <div v-if="isMenuOpen" class="menu">
      <button @click="toggleMenu" class="close-button" aria-label="Close menu">×</button>
      <nav class="menu-nav">
        <router-link to="/blog" class="menu-item-title-2" @click="toggleMenu">Blog</router-link>
        <div class="menu-item projects-section">
          <p class="menu-item-title">Projects</p>
          <div class="projects-submenu">
            <a v-for="project in projects" :key="project.id" :href="project.url" target="_blank" class="project-link">
              {{ project.title }}
            </a>
          </div>
        </div>
      </nav>
    </div>

    <!-- Main content -->
    <main>
      <router-view></router-view>
    </main>

    <footer>
      <p>&copy; {{ currentYear }} Hale.se All rights reserved.</p>
    </footer>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const isMenuOpen = ref(false)
const currentYear = computed(() => new Date().getFullYear())

const projects = [
  { id: 1, title: 'ProjectPHP', url: '#' },
]

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.menu-button {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 24px;
  background: none;
  border: 1px solid #000;
  cursor: pointer;
  z-index: 1000;
  padding: 5px 10px;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background: white;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  z-index: 1001;
  overflow-y: auto;
}

.close-button {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  float: right;
  padding: 5px;
}

.menu-nav {
  margin-top: 40px;
}

.menu-item {
  display: block;
  padding: 10px 0;
  color: #333;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.projects-section {
  background-color: transparent;
}

.projects-section:hover {
  background-color: transparent;
}

.menu-item-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
}

.menu-item-title-2 {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 16px;
  text-decoration: none;
  color: #000;
}

.projects-submenu {
  margin-left: 15px;
}

.project-link {
  display: block;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.project-link:hover {
  background-color: #f0f0f0;
  transform: translateX(5px);
}

header {
  text-align: center;
}

h1 {
  font-size: 48px;
}

.biography {
  text-align: justify;
}

.bio-image {
  float: right;
  margin: 0 0 20px 20px;
  border-radius: 50%;
}

h2 {
  font-size: 24px;
}

footer {
  margin-top: 40px;
  text-align: center;
  color: #666;
}
</style>