import { createRouter, createWebHistory } from 'vue-router'
import BlogPage from '@/components/BlogPage.vue'
import HomePage from '@/components/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/blog',
    name: 'Blog',
    component: BlogPage
  },
  // ... other routes ...
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router